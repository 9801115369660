import React from "react"
import { css } from "@emotion/core"

import { mediaQueries, container, fonts, weights } from "../../styles"
import Layout from "../../components/layout"
import FullWidthSection from "../../components/FullWidthSection"
import Button from "../../components/Button"
import { navigate } from "gatsby"

const General = () => {
  const wrapperStyles = css`
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: ${fonts.sans};
    font-weight: ${weights.light};
    letter-spacing: 0.2px;

    strong:only-child {
      display: block;
      margin-top: 60px;
      margin-bottom: -7px;
      font-family: inherit;
      font-size: 21px;
      line-height: 1.4px;
      font-weight: ${weights.bold};
    }

    ${mediaQueries.phoneLarge} {
      padding-top: 100px;
      padding-bottom: 100px;

      strong:only-child {
        font-size: 18px;
        line-height: 1.39;
        margin-bottom: -27px;
      }

      ul {
        font-size: 16px;
        line-height: 1.6875;
      }
    }
  `

  return (
    <Layout
      headerData={{
        title: "募集概要",
        mobileMinHeight: "93vh",
        children: (
          <>
            <Button
              css={css`
                margin-top: 64px;

                ${mediaQueries.xs} {
                  margin-top: 72px;
                }
              `}
              onClick={() => navigate("/contact")}
            >
              応募
            </Button>
          </>
        ),
      }}
    >
      <FullWidthSection height="100%" padding="0">
        <div css={[container.medium, wrapperStyles]}>
          <table>
            <tbody>
              <tr>
                <td>給与</td>
                <td>
                  月給制、年俸制（職制により異なる）
                  <br />
                  あなたの経験・年齢・能力を考慮のうえ優遇します。
                </td>
              </tr>
              <tr>
                <td>昇給</td>
                <td>年1回</td>
              </tr>
              <tr>
                <td>賞与</td>
                <td>年2回（6月、12月）</td>
              </tr>
              <tr>
                <td>手当</td>
                <td>通勤交通手当、住宅手当、残業手当、等（各種条件による）</td>
              </tr>
              <tr>
                <td>勤務地</td>
                <td>
                  〒110-0005
                  <br />
                  東京都台東区上野3-18-13今井ビル2F
                </td>
              </tr>
              <tr>
                <td>勤務時間</td>
                <td>9:30～1８:30</td>
              </tr>
              <tr>
                <td>休日</td>
                <td>
                  完全週休2日制（土・日）国民の祝日、年次有給休暇、その他の休暇（慶弔休暇、夏期休暇、年末年始休暇）
                </td>
              </tr>
              <tr>
                <td>保険</td>
                <td>関東ITソフトウェア健康保険組合、厚生年金保険、雇用保険</td>
              </tr>
              <tr>
                <td>勤務時間</td>
                <td>9:30～1８:30</td>
              </tr>
            </tbody>
          </table>
        </div>
      </FullWidthSection>
    </Layout>
  )
}

export default General
